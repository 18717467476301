.tollium__datetime {
  position: relative;
  display: inline-flex;
  flex-flow: row;
  align-items: center;
  border: 1px inset #cbcbcb;
  padding: 1px 2px;
  height: 23px;
  line-height: 23px;
  border: 1px solid #b3b3b3;
  border-radius: 2px;
  background: #fcfbfb;
}
.tollium__datetime--replaced {
  position: absolute !important;
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 0;
}
.tollium__datetime__sep {
  display: inline-block;
}
.tollium__datetime__part {
  background: transparent;
  border: 0;
  width: calc(2ch + 10px);
  text-align: center;
  font: inherit;
  padding: 0;
  margin: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield !important;
}
.tollium__datetime__part::-webkit-outer-spin-button, .tollium__datetime__part::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.tollium__datetime__year {
  width: calc(4ch + 8px);
}
.tollium__datetime__msec {
  width: calc(3ch + 8px);
}
.tollium__datetime__togglepicker, .tollium__datetime__reset {
  display: inline-block;
  cursor: pointer;
}
.tollium__datetime__picker {
  position: fixed;
  background-color: #ffffff;
}
.tollium__datetime:focus-within {
  border-color: #52aefe;
}
.tollium__datetime--required {
  background-color: #fcf8d0;
  border-color: #b3b3b3;
}
.tollium__datetime--required:focus {
  border-color: #52aefe;
}
.tollium__datetime--disabled {
  border-color: #b3b3b3;
  color: rgba(0, 0, 0, 0.5);
  background: url("~@mod-tollium/web/ui/skins/default/bgdisabled.svg") 0 0/48px 48px;
  opacity: 1;
}
.tollium__datetime--disabled:focus {
  border-color: #b3b3b3;
}
.tollium__datetime--disabled .tollium__datetime__togglepicker, .tollium__datetime--disabled .tollium__datetime__reset {
  opacity: 0;
  pointer-events: none;
}
.tollium__datetime__controls {
  padding-left: 2px;
}

input[type=tel].tollium__datetime__part {
  border: none;
  padding: 0;
  min-height: 0;
  min-width: 0;
  height: auto;
  width: 3ch;
}
input[type=tel].tollium__datetime__part:first-of-type(input), input[type=tel].tollium__datetime__part:last-of-type(input) {
  width: 3ch;
}

input[type=tel].tollium__datetime__msec {
  width: calc(3ch + 2px);
}

input[type=tel].tollium__datetime__year {
  width: calc(4ch + 2px);
}

input[type=tel].tollium__datetime__time {
  padding: 0 5px 0 3px;
}

.tollium__datetime__togglepicker:after {
  display: inline-block;
  content: "";
  width: 16px;
  height: 16px;
  background: 50% 50%/16px 16px no-repeat;
  vertical-align: middle;
  background-image: url(~@mod-tollium/web/ui/skins/default/datepicker.16x16.b.svg);
}

.tollium__datetime__reset:after {
  display: inline-block;
  content: "";
  width: 16px;
  height: 16px;
  background: 50% 50%/16px 16px no-repeat;
  vertical-align: middle;
  background-image: url(~@mod-tollium/web/ui/skins/default/reset.16x16.b.svg);
}

.tollium__datetime__picker {
  border: 1px solid #a7a7a7;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  display: inline-block;
  border-radius: 3px;
  background: #e5e5e5;
}

.tollium__datetime__picker__header {
  display: flex;
  padding: 2px 3px;
}

.tollium__datetime__picker__previous,
.tollium__datetime__picker__next {
  display: inline-block;
  width: 16px;
  vertical-align: middle;
  cursor: pointer;
  flex: 1 0 16px;
  line-height: 22px;
}

.tollium__datetime__picker__previous {
  background: url(~@mod-tollium/web/ui/skins/default/arrow-left.b.svg) 0% 50%/16px 16px no-repeat;
}

.tollium__datetime__picker__next {
  background: url(~@mod-tollium/web/ui/skins/default/arrow-right.b.svg) 100% 50%/16px 16px no-repeat;
}

.tollium__datetime__picker__yearselect {
  background: #fcfbfb;
  border: 1px solid #b3b3b3;
  border-radius: 2px;
  line-height: 22px;
  height: 23px;
  margin-left: 3px;
  padding: 0 5px;
  width: 70px;
}

.tollium__datetime__picker__buttonbar {
  display: flex;
  padding: 8px 7px;
}

.tollium__datetime__picker__todaybutton {
  background-image: linear-gradient(to bottom, #0098EE 0%, #0285CF 100%);
  color: #ffffff;
}
.tollium__datetime__picker__todaybutton:hover {
  background-image: linear-gradient(to top, #0098EE 0%, #0285CF 100%);
}

.tollium__datetime__picker__buttonflex {
  flex-grow: 1;
}

.tollium__datetime__picker__days {
  border-collapse: collapse;
  padding: 0;
  margin: 0;
  border-top: 1px solid #a7a7a7;
  border-bottom: 1px solid #a7a7a7;
  background: #fcfbfb;
}

.tollium__datetime__picker__weekday {
  height: 30px;
  padding: 10px 4px;
  border-bottom: 1px solid #a7a7a7;
  color: #0097ec;
  font-weight: normal;
}

.tollium__datetime__picker__weeknr {
  border-right: 1px solid #a7a7a7;
  padding: 4px 8px 4px;
  color: #0097ec;
  line-height: 19px;
}

.tollium__datetime__picker__weeknr > span {
  display: inline-block;
  min-width: 15px;
  padding-top: 2px;
}

.wh-calendar > .wh-calendar-days td.day {
  padding-bottom: 0;
  padding-top: 0;
}

.tollium__datetime__picker__days th,
.tollium__datetime__picker__days td {
  width: 32px;
  height: 22px;
  padding: 5px;
  min-width: 40px;
}
@media (max-height: 640px) {
  .tollium__datetime__picker__days th,
  .tollium__datetime__picker__days td {
    width: 5vh;
    height: 3.2vh;
    padding: 0.8vh;
    min-width: 6.25vh;
  }
}

.tollium__datetime__picker__days td:focus {
  outline: none;
}

.tollium__datetime__picker__days span {
  display: block;
  height: 29px;
  width: 100%;
  line-height: 19px;
  text-align: center;
  padding: 4px;
  border: 1px solid transparent;
  border-radius: 2px;
}

.tollium__datetime__picker__days th span {
  font-weight: normal;
  color: #0097ec;
}

.tollium__datetime__picker__day--today > span {
  background-color: #eee;
  border-color: #ccc;
}

.tollium__datetime__picker__day--selected > span {
  background-color: #0097ec;
  border-color: #0097ec;
  color: #fff;
}

.tollium__datetime__picker__day:not(.tollium__datetime__picker__day--disabled) {
  cursor: pointer;
}

.tollium__datetime__picker__day:not(.tollium__datetime__picker__day--disabled):hover > span {
  background-color: #eee;
  border-color: #0097ec;
  color: inherit;
}

.tollium__datetime__picker__day--disabled > span {
  opacity: 0.5;
}

.tollium__datetime__picker__day--othermonth {
  color: #999;
}